<template>
    <div class="qrcode" ref="qrcodeurl"></div>
</template>

<script>
import QRCode from 'qrcodejs2';
export default {
    name:"test2",
    data() {
        return {
            qrcode: null,
        }
    },
    mounted() {
        this.createQRCode(); 
    },
    methods: {
        createQRCode() {
        this.qrcode = new QRCode(this.$refs.qrcodeurl, {
            text: 'https://syb-test.allinpay.com/apiweb/h5unionpay/native?key=pYsSgCJ318wVG4r2KDJFRfUC', // 替换为实际的链接
            width: 150,
            height: 150,
            colorDark: '#000000',
            colorLight: '#ffffff',
        });
        },
    },

}
</script>

<style>

</style>