<template>
  <div >
    <el-form :model="list" ref="editForm"  :rules="rules">
        <vxe-modal v-model="val" title="新增房源" width="1000" height="850" show-zoom  :before-hide-method="clear" class="uu">
            <div>

                <div style="display: flex;">
                    <el-form-item label="小区地址"  prop="selectedOptions" size="small" style="flex: 1;"> &nbsp;&nbsp;<span style="color: red;">(注:标*为必填项)</span><br>
                        <el-select v-model="selectedOptions" placeholder="请选择">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item  prop="addressValue" size="small" style="flex: 1;">
                        <el-select v-model="addressValue" filterable placeholder="请选择小区" :filter-method="addressSelect"  style="width: 730px; margin-top: 32px;" no-data-text="输入小区以获取数据">
                            <el-option
                                v-for="item in addressOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.name"
                                >
                            </el-option>
                        </el-select> 
                    </el-form-item>
                </div>
                    <div>
                    <el-row>
                        <el-col :span="9">
                            <span>户型</span> &nbsp;&nbsp;<span style="color: red;">(注:仅能输入一位数字)</span>
                        </el-col>
                        <el-col :span="8">
                            <span> &nbsp;出租价格</span>
                        </el-col>
                        <el-col :span="4">
                            <span> &nbsp;出租面积</span>
                        </el-col>
                        <el-col :span="3">
                            <span> &nbsp;佣金</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="2">
                            <el-form-item label="室" class="label-r" prop="chamber">
                                <el-input v-model="list.chamber"  class="input-r" maxlength="1" type="number"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                            <el-form-item label="厅" class="label-r" prop="office">
                                <el-input v-model="list.office"  class="input-r" maxlength="1" type="number"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                            <el-form-item label="厨" class="label-r" prop="kitchen">
                                <el-input v-model="list.kitchen"  class="input-r" maxlength="1" type="number"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item label="卫" class="label-r" prop="toilet">
                                <el-input v-model="list.toilet"  class="input-r" maxlength="1" type="number"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item label="元" class="label-ri" prop="price">
                                <el-input v-model.number="list.price"   class="input-r" style="width: 60px;" clearable maxlength="6" type="number"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="1">
                            <div style="padding-left: 10px;padding-top: 8px;"><span>|</span></div>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item class="label-ri" prop="paymentType">
                                <el-select v-model="list.paymentType" placeholder="支付方式"  clearable  class="select-r">
                                <el-option
                                    v-for="item in paymentOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="parseInt(item.value)"
                                >
                                </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="m²" class="label-ri" prop="area">
                                <el-input v-model.number="list.area"   class="input-r" style="width: 55px;" clearable maxlength="4"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item label="元" class="label-ri" prop="commission">
                                <el-input v-model.number="list.commission"   class="input-r" style="width: 55px;" clearable maxlength="6"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row style="height: 50px;">
                        <el-col>
                        <el-form-item label="租房方式" prop="rentalType">
                            <el-radio-group v-model="list.rentalType" >
                                <el-radio  :label=0>合租</el-radio>
                                <el-radio  :label=1>整租</el-radio>
                                <el-radio  :label=2>独栋</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row style="height: 50px;">
                        <el-col>
                        <el-form-item label="租期" prop="leaseTerm">
                            <el-radio-group v-model="list.leaseTerm" >
                                <el-radio  :label=0>月</el-radio>
                                <el-radio  :label=1>季</el-radio>
                                <el-radio  :label=2>半年</el-radio>
                                <el-radio  :label=3>整年</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                            <el-form-item label="房屋推广标题" class="beizhu" prop="name">
                                <el-input v-model="list.name"   clearable   autosize   maxlength="30" ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                        <el-form-item label="房屋推广描述" class="beizhu" prop="synopsis">
                            <el-input v-model="list.synopsis"   clearable  type="textarea" autosize   maxlength="250" ></el-input>
                        </el-form-item>
                        </el-col>
                    </el-row>
                    </div>
                    <div>
                        <el-form-item label="房间图片" prop="files"><br>
                            <el-upload
                            :action=uploadeUrl
                            list-type="picture-card"
                            :auto-upload="false"
                            :limit="12"
                            :on-exceed="handleExceed"
                            :before-upload="beforeCategoryPicUpload"
                            :file-list="files"
                            ref="uploads"
                            :multiple="multiple"
                            :http-request="uploadFile"

                            >
                                <i slot="default" class="el-icon-plus"></i>
                                <div slot="file" slot-scope="{file}">
                                <img
                                    class="el-upload-list__item-thumbnail"
                                    :src="file.url" alt=""
                                >      
                                <span class="el-upload-list__item-actions">
                                    <span
                                    v-if="!disabled"
                                    class="el-upload-list__item-delete"
                                    @click="handleRemove(file)"
                                    >
                                    <i class="el-icon-delete"></i>
                                    </span>
                                </span>                  
                                </div>
                            </el-upload>
                        </el-form-item>  
                    </div>
                    <div>
                        <span>更多信息</span><br><br>
                        <el-row>
                            <el-col :span="6">
                                <span>楼栋/单元</span>
                            </el-col>
                            <el-col :span="6">
                                <span>楼层</span>
                            </el-col>
                            <el-col :span="6">
                                <span>电梯信息</span>
                            </el-col>
                            <el-col :span="6">
                                <span>朝向</span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="6">
                                <el-input v-model.number="list.building"   class="input-r" style="width: 100px; margin-top: 15px;" >
                                    <template #suffix>
                                        <span>栋</span>
                                    </template>
                                </el-input>
                                <el-input v-model.number="list.unit"   class="input-r" style="width: 100px; margin-top: 15px;" >
                                    <template #suffix>
                                        <span>单元</span>
                                    </template>
                                </el-input>
                            </el-col>
                            <el-col :span="6">
                                <el-input v-model.number="list.level"   class="input-r" style="width: 210px; margin-top: 15px;" type="number">
                                    <template #suffix>
                                        <span>楼层</span>
                                    </template>
                                </el-input>
                            </el-col>
                            <el-col :span="6">
                                    <el-select v-model="list.lift" placeholder="电梯信息"  clearable  class="select-r"  @change="replacePerChange($event)">
                                    <el-option
                                        v-for="item in liftOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    >
                                    </el-option>
                                    </el-select>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item >
                                    <el-select v-model="list.faceTo" placeholder="朝向"  clearable  class="select-r"  @change="replacePerChange($event)">
                                    <el-option
                                        v-for="item in towardOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    >
                                    </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="6">
                                <span>装修类型</span>
                            </el-col>
                            <el-col :span="6">
                                <span>入住性别要求</span>
                            </el-col>
                            <el-col :span="6">
                                <span>可入住时间</span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="6">
                                <el-select v-model="list.renovation" placeholder="装修"  clearable  class="select-r"  @change="replacePerChange($event)">
                                    <el-option
                                        v-for="item in renovationOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    >
                                    </el-option>
                                    </el-select>
                            </el-col>
                            <el-col :span="6">
                                <el-select v-model="list.sex" placeholder="性别"  clearable  class="select-r"  @change="replacePerChange($event)">
                                    <el-option
                                        v-for="item in sexOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    >
                                    </el-option>
                                    </el-select>
                            </el-col>
                            <el-col :span="6">
                                <el-date-picker
                                v-model="list.checkInTime"
                                align="right"
                                type="date"
                                placeholder="选择日期"
                                :picker-options="pickerOptions"
                                
                                >
                                </el-date-picker>
                            </el-col>
                        </el-row>
                    </div>
                    <div style="margin-top: 20px;">                       
                        <el-row>
                            <span>房间物品</span>&nbsp;&nbsp;
                            <el-checkbox :indeterminate="isIndeterminate" v-model="list.checkAll" @change="handleCheckAllChange">全选</el-checkbox>                           
                            <el-checkbox-group v-model="roomTag" @change="handleCheckedCitiesChange">
                                <el-col v-for="city in cities" :key="city" :span="3">
                                    <el-checkbox  :label="city" >{{city}}</el-checkbox>
                                </el-col>
                            </el-checkbox-group>
                         </el-row>
                    </div>

                
                
            <div class="centered-content">
                    <el-button type="primary" @click="add()"  size="small">确 定</el-button>
                    <el-button size="small"   @click="clear()">取 消</el-button>
            </div> 
            </div>
        </vxe-modal>
    </el-form>
    <Test ref="Test"></Test>
  </div>
</template>

<script>
import Test from '../map.vue'
//import {CodeToText} from 'element-china-area-data-new2'
const cityOptions = ['空调', '冰箱', '洗衣机', '微波炉','饮水机', '电视', '电磁炉', '油烟机','热水器', '燃气灶', '暖气', '座椅','床', '书桌', '沙发', '衣柜','床头柜', '灯', '窗帘',  '办公桌', '办公椅', '会议桌', 'WIFI', '智能门锁'];
export default {
    name:'addProperty',
    components:{
        Test
    },
    data: function (){
      return{
        val:false,
        list:{checkInTime:''},
        headers:{},
        formPicsData:'',
        multiple:true,
        uploadeUrl:this.$httpUrl+'/applet/multipleImageUpload',
        options:[],
        selectedOptions: '',
        files:[],
        addressOptions:[],
        paymentOptions:[{ label: '月付', value: '0' },{ label: '季付', value: '1' }],
        liftOptions:[{ label: '有电梯', value: '0' },{ label: '无电梯', value: '1' }],
        towardOptions:[{ label: '北', value: '0' },{ label: '东', value: '1' },{ label: '南', value: '2' },{ label: '西', value: '3' }],
        renovationOptions:[{ label: '精装', value: '0' },{ label: '简装', value: '1' },{ label: '毛坯', value: '2' },{ label: '豪华装修', value: '3' }],
        sexOptions:[{ label: '不限性别', value: '0' },{ label: '限男生', value: '1' },{ label: '限女生', value: '2' }],
        roomTag: [],
        cities: cityOptions,
        disabled: false,
        isIndeterminate: true,
        addressValue:'',
        imgUrl:[],
        wuhan:[{value: 'wuhan',label: '武汉市'}],
        beijing:[{value:'beijing',label:'北京市'}],
        shanghai:[{value:'shanghai',label:'上海市'}],
        shenzhen:[{value:'shenzhen',label:'深圳市'}],
        hangzhou:[{value:'hangzhou',label:'杭州市'}],
        nanjing:[{value:'nanjing',label:'南京市'}],
        suzhou:[{value:'suzhou',label:'苏州市'}],
        diqu:[{value:'武汉市',label:'武汉市'},{value:'北京市',label:'北京市'},{value:'上海市',label:'上海市'},{value:'深圳市',label:'深圳市'},{value:'杭州市',label:'杭州市'},{value:'南京市',label:'南京市'},{value:'合肥市',label:'合肥市'},{value:'长沙市',label:'长沙市'},{value:'西安市',label:'西安市'}],      
        user: JSON.parse(sessionStorage.getItem('user')),
        pickerOptions: {
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          }, {
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          }, {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }]
        },
        rules: {
            selectedOptions: [
              {validator: (rule, value,callback)=> {
                if (this.selectedOptions.length===0) {
                  callback(new Error("请选择地址"));
                } else {
                  callback();
                }}, trigger: 'change' }
            ],
            addressValue: [
            {validator: (rule, value,callback)=> {
                if (this.addressValue=='' || this.addressValue=='undefined') {
                  callback(new Error("请选择地址"));
                } else {
                  callback();
                }}, trigger: 'change' }
            ],
            checkInTime: [
              { required: true, message: '请输入期望入住时间', trigger: 'change' }
            ],
            chamber: [
              { required: true, message: '请输入户型', trigger: 'change' },
              { pattern: /^\d{1}$/, message:'格式错误', trigger: ['blur'] }
            ],
            office: [
              { required: true, message: '请输入户型', trigger: 'change' },
              { pattern: /^\d{1}$/, message:'格式错误', trigger: ['blur'] }
            ],
            kitchen: [
              { required: true, message: '请输入户型', trigger: 'change' },
              { pattern: /^\d{1}$/, message:'格式错误', trigger: ['blur'] }
            ],
            toilet: [
              { required: true, message: '请输入户型', trigger: 'change' },
              { pattern: /^\d{1}$/, message:'格式错误', trigger: ['blur'] }
            ],
            price:[
              { required: true, message: '请输入租金', trigger: 'change' },
            ],
            commission:[
              { required: true, message: '请输入佣金', trigger: 'change' },
            ],
            area:[
              { required: true, message: '请输入面积', trigger: 'change' },  
            ],
            synopsis:[
              { required: true, message: '请输入概述', trigger: 'change' },    
            ],
            paymentType: [
              { required: true, message: '请选择支付方式', trigger: 'change' }
            ],
            rentalType: [
              { required: true, message: '请选择租房方式', trigger: 'blur' }
            ],
            leaseTerm:[
              { required: true, message: '请选择租房方式', trigger: 'blur' }
            ],
            name:[
                { required: true, message: '请输入标题', trigger: 'change' }
            ],
            files:[
            { validator: (rule, value,callback)=> {
                if (this.$refs.uploads.uploadFiles.length===0) {
                  callback(new Error("请选择图片"));
                } else {
                  callback();
                }}, message: '请选择图片', trigger: 'change' }
            ]
          },
      }
    },
    computed: {
        timeDefault() {
            var date = new Date();
            var s1 = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + (date.getDate());
            return s1;
        }
    },
    watch: {
        addressValue(newValue) {
            this.addressOptions.forEach(item => {
                if(item.name==newValue){
                    this.list.longitude=item.location.lng
                    this.list.latitude=item.location.lat
                    
                    this.$refs.Test.AllAddress(this.list.longitude,this.list.latitude,0)
                }
            });
        },
    },
    mounted:function(){
        this.$bus.$on('getAddressOptionsAdd', (data)=>{
            this.addressOptions=data
        })
        this.$bus.$on('getAllAddressAdd', (data)=>{
            console.log('地址信息',data)
            this.list.allAddress=data.regeocode.formattedAddress
            this.list.economize=data.regeocode.addressComponent.province
            this.list.market=data.regeocode.addressComponent.city
            this.list.distinguish=data.regeocode.addressComponent.district
        })
        //this.options=this.diqu
        this.list.faceTo=this.towardOptions[0].value
        this.list.renovation=this.renovationOptions[0].value
        this.list.sex=this.sexOptions[0].value
        this.list.lift=this.liftOptions[0].value
        this.list.checkInTime = this.timeDefault;

        const user = JSON.parse(window.sessionStorage.getItem('user'))
        this.headers.token=user.token

            switch(user.area){
                case 0:
                    this.options=this.wuhan;
                    this.selectedOptions='wuhan'
                    break;
                case 1:
                    this.options=this.beijing;
                    this.selectedOptions='beijing'
                    break;
                case 2:
                    this.options=this.shanghai;
                    this.selectedOptions='shanghai'
                    break;    
                case 3:
                    this.options=this.shenzhen;
                    this.selectedOptions='shenzhen'
                    break;    
                case 4:
                    this.options=this.hangzhou;
                    this.selectedOptions='hangzhou'
                    break;
                case 5:
                    this.options=this.nanjing;
                    this.selectedOptions='nanjing'
                    break;
                case 6:
                    this.options=this.suzhou;
                    this.selectedOptions='suzhou'
                    break;  
            }
        
    },
    beforeDestroy() {
      // 在该组件弃用删除之后，解绑该事件，减轻$bus的负担
      this.$bus.$off('getAddressOptionsAdd')
      this.$bus.$off('getAllAddressAdd')
    },
    methods:{
        addressSelect(value){
           this.$refs.Test.getPrompt(value,0)
        },
        open(){
            this.val=true
        },
        clear(){
            this.roomTag=[]
            this.val=false
            this.list={}
            this.$refs.editForm.resetFields()
            this.$refs.uploads.clearFiles()
            
            this.list.faceTo=this.towardOptions[0].value
            this.list.renovation=this.renovationOptions[0].value
            this.list.sex=this.sexOptions[0].value
            this.list.lift=this.liftOptions[0].value
            this.list.checkInTime = this.timeDefault;

        },
        handleRemove(file) {
            let fileList = this.$refs.uploads.uploadFiles
            fileList.forEach((item,index)=>{
                if(item.uid==file.uid){
                    fileList.splice(index, 1);
                }
            })
        },
        handleCheckAllChange(val) {
            this.roomTag = val ? cityOptions : [];
            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.list.checkAll = checkedCount === this.cities.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
        },
        handleExceed() {
            this.$message.error('当前限制选择 12 张图片!');
        },
        changeFileLength(fileList){
            this.filesLength = fileList.length
            this.BSfileList = fileList
        },
        beforeCategoryPicUpload(file) {
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg';
            const isLt5M = file.size / 1024 / 1024 < 5;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!');
                this.$refs.uploads.abort(false);
                
            }
            if (!isLt5M) {
                this.$message.error('上传头像图片大小不能超过 5MB!');
                this.$refs.uploads.abort(false);
               
            }
            return isJPG && isLt5M;
        },
        uploadFile(file) {
            this.formPicsData.append('files', file.file);
        },
        add(){
            this.$refs.editForm.validate((valid) => {
                if (valid) {
                    //先将图片保存下来，返回图片的访问路径与文件名
                    this.formPicsData = new FormData();
                    this.$refs.uploads.submit();
                    let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                    }
                    this.$axios.post(this.$httpUrl+"/applet/multipleImageUpload", this.formPicsData, config)
                    .then(res => {
                        if(res.data.data!=null){
                            this.imgUrl=res.data.data  
                            this.list.imgUrl=this.imgUrl   
                        }
                        return this.uploadInfo()
                    })
                }
            })

        },
        uploadInfo(){
            let a = ''
            //把房间标签数组转换成字符串保存
            this.roomTag.find((item) => {

                a=a+item+','
            })

            this.list.roomTag=a.slice(0, a.length - 1);

            this.list.address=this.addressValue
            this.list.userId=this.user.id
            this.list.phone=this.user.phone

            //提交表单信息
            this.$axios.post(this.$httpUrl+"/applet/edit", this.list)
            .then(res=>{
                if(res.data.code===1){
                    this.$message({message: res.data.data,type: 'success'});
                }
                this.roomTag=''
                this.addressValue=''
                this.val=false
                this.list={}
                this.$bus.$emit('getHouse')
                

            })
            
        },
        replacePerChange(val){
            this.$forceUpdate()
            console.log(val)
        },



    }
}
</script>

<style scoped>
::v-deep .el-form-item__label{
  padding: 0 0 0 5px;

}
::v-deep .el-input__inner{

  border-radius: 0px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;

}
::v-deep .input-r{
  padding-left: 5px;
  padding-right: 5px;
  width: 20px;
  float: left;
}
::v-deep .label-r .el-form-item__label{
  float: right;
  padding-right: 10px;
}

.label-r{
  width: 70px;
  
}
.label-ri{
  width: 100px;
}
::v-deep .label-ri .el-form-item__label{
  float: right;
  padding-right: 0px;
}
::v-deep .input-r .el-input__inner{
 padding-left: 5px;
 padding-right: 5px;
 height: 25px;
} 

::v-deep .label-ri .el-form-item__content .select-r .el-input .el-input__inner{
  height: 25px;
} 

::v-deep .el-radio{
  padding-left: 15px;
}

.centered-content{
  display: flex; /* 创建 Flexbox 布局 */
  justify-content: center; /* 水平居中对齐 */
  align-items: center; /* 垂直居中对齐 */
  margin-top: 30px;
}

::v-deep .el-form-item__error{
  top: 85%;
}
::v-deep .el-textarea__inner{
  border-radius: 0px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
::v-deep .beizhu .el-form-item__label {
    height: 25px;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item{
    height: 90px;
    width: 90px;

}
::v-deep .el-upload--picture-card{
    height: 90px;
    width: 90px;
    line-height: 100px;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item-thumbnail{
    height: 90px;
    width: 90px;
}

/*滚动条整体部分*/
.uu ::-webkit-scrollbar {
  width: 7px;
  height: 10px;
}
/*滚动条的轨道*/
.uu ::-webkit-scrollbar-track {
  background-color: #FFFFFF;
  
}
/*滚动条里面的小方块，能向上向下移动*/
.uu ::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 5px;
  border: 1px solid #F1F1F1;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  
}
.uu ::-webkit-scrollbar-thumb:hover {
  background-color: #A8A8A8;
}
.uu ::-webkit-scrollbar-thumb:active {
  background-color: #787878;
}



</style>

<style>
.el-cascader .el-input .el-input__inner{
  height: 32px;
  width: 220px;
  padding-left: 10px;
  padding-right: 0px;
  margin-right:5px;

}

.bottomAlignment{
  height: 10px;
  padding-top: 60px;
  padding-left: 10px;
}



</style>