import VueRouter from 'vue-router';
import Index from '../components/Index'
import Users from '../components/Users'
import Clue from '../components/clue/clueManagement'
import Personal from '../components/Personal/Personal'
import Order from '../components/order/order'
import Property from '../components/property/propertyManagement'
import Test from '../components/map'
import Test1 from '../components/test2'




const routes =[
    {
        path:'/',
        name:'login',
        component:() => import('../components/Login')
    },
    {
        path:'/Index',
        name:'index',
        component:Index,
        redirect:'index/house',
        children:[
            {path: 'House',component:Property},
            {path: 'Users',component: Users},
            {path: 'Clue',component: Clue},
            {path: 'Personal',component:Personal},
            {path: 'Order',component:Order},
            {path: 'Test',component:Test},
            {path: 'Teste',component:Test1}
        ]
    },

]


const router = new VueRouter({
    mode:'hash',
    routes
})

router.beforeEach((to, from, next) => {

    const user  = JSON.parse(sessionStorage.getItem('user'))

    if(to.path==='/index/users' || to.path==='/index/order'){
        if(sessionStorage.getItem('token')==='' || sessionStorage.getItem('token')==='undefined' || sessionStorage.getItem('token')==='null' || user.identity===0){
            console.log('判断身份')
            next('/')
        }
    }
    
    next()
})



export default router;