<template>
  <div>
        <div class="conditionBox">
        <el-form ref="form" :model="roomDto">
            <el-row :gutter="20">
                <el-col :span="6">
                <el-form-item label="租期" prop="saletype"  >
                    <el-select v-model="roomDto.leaseTerm" placeholder="请选择"  clearable  class="select-r">
                    <el-option
                        v-for="item in leaseTermOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                    </el-select>
                </el-form-item>
                </el-col>
                <el-col :span="6">
                <el-form-item label="租房方式" prop="saletype" >
                    <el-select v-model="roomDto.rentalType" placeholder="请选择"  clearable  class="select-r">
                    <el-option
                        v-for="item in rentalOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                    </el-select>
                </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="状态" prop="saletype" >
                    <el-select v-model="roomDto.groundingStatus" placeholder="请选择"  clearable  class="select-r">
                    <el-option
                        v-for="item in statusOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="parseInt(item.value)"
                    >
                    </el-option>
                    </el-select>
                </el-form-item>
                </el-col>
                <el-col :span="6">
                <el-form-item label="地址">
                    <el-input v-model="roomDto.address" placeholder="地址" class="input-r" clearable> </el-input>
                </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="6">
                    <el-form-item label="租金" >
                    <el-input v-model="roomDto.priceMin" placeholder="最小值" class="input-r" clearable></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                <el-form-item>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;至</span>
                    <el-input v-model="roomDto.priceMax" placeholder="最大值" class="input-r" clearable></el-input>
                </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="佣金" >
                    <el-input v-model="roomDto.commissionMin" placeholder="最小值" class="input-r" clearable></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                <el-form-item>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;至</span>
                    <el-input v-model="roomDto.commissionMax" placeholder="最大值" class="input-r" clearable></el-input>
                </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="button-r">
            <el-button type="primary" @click="getHouse()" icon="el-icon-search" size="small" plain>查询</el-button>
            <el-button type="info"    @click="resetting()" icon="el-icon-refresh" size="small"  plain>重置</el-button>
        </div>
        
    </div>

    <div class="table-r">
        <div class="buttonBox">
            <el-button style="font-size: 13px;" v-if="checkStatus==0" type="primary"  @click="add()"  icon="el-icon-plus" size="small" plain>新增房源</el-button>
            <el-button style="font-size: 13px;" v-if="checkStatus==0" type="success" @click="grounding(0)" icon="el-icon-search" size="small" plain>查询已上架({{upSum}})</el-button>
            <el-button style="font-size: 13px;" v-if="checkStatus==0" type="danger" @click="grounding(1)" icon="el-icon-search" size="small" plain>查询未上架({{downSum}})</el-button>
            <el-button style="font-size: 13px;" v-if="checkStatus==1" type="success" @click="editGrounding(0)" icon="el-icon-upload2" size="small" plain>上架房源</el-button>
            <el-button style="font-size: 13px;" v-if="checkStatus==1" type="danger" @click="editGrounding(1)" icon="el-icon-download" size="small" plain>下架房源</el-button>
            <el-button style="font-size: 13px;" v-if="checkStatus==1" type="danger"   @click="deleteBatch()" icon="el-icon-delete" size="small" plain>批量删除</el-button>
        </div>
            <vxe-table
            border
            show-header-overflow
            show-overflow
            :row-config="{height: 40,isHover: true,isCurrent: true}"
            height="500px"
            :data="list"
            :loading="loading"
            :loading-config="{icon: 'vxe-icon-indicator roll', text: '正在拼命加载中...'}"
            stripe
            round
            @current-change="currentChangeEvent"
            :seq-config="{startIndex: (currentPage - 1) * pageSize}"
            ref="xTable"
            align="center"
            class="mytable-scrollbar"
            style="width: 1660px;"
            @checkbox-all="selectAllEvent"
            @checkbox-change="selectChangeEvent"
            >
                <vxe-column type="checkbox" width="60"></vxe-column>
                <vxe-column type="seq" title="序号" width="60"></vxe-column>
                <vxe-column field="address" title="地址" width="300px"  ></vxe-column>
                <vxe-column field="saletype" title="户型"     :formatter="formatterSaletype"> </vxe-column>
                <vxe-column field="rentalType" title="租房方式"  :formatter="formatterRental"></vxe-column>
                <vxe-column field="price" title="租金"  ></vxe-column>
                <vxe-column field="commission" title="佣金"   ></vxe-column>
                <vxe-column field="area" title="面积"   :formatter="formatterArea"></vxe-column>
                <vxe-column field="groundingStatus" title="状态" :formatter="formatterStatus" ></vxe-column>
                <vxe-column field="createTime" title="上传时间"  ></vxe-column>
            </vxe-table>
        <div style="float: right;">
            <vxe-pager
                background
                :current-page.sync="currentPage"
                :page-size.sync="pageSize"
                :total="totalResult"
                :layouts="['PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']"
                :page-sizes="pageSizes"
                @page-change="handleCurrentChange"
                align="left"
                size="mini" 

                >
            </vxe-pager>
        </div>
    </div>
    <addProperty ref="addProperty"></addProperty>
    <Info ref="Info"></Info>
  </div>
</template>

<script>
import addProperty from './addProperty'
import Info from './info'


export default {
    name:'propertyManagement',
    components:{
        addProperty,
        Info,
       
    },
    data: function (){
      return{
        roomDto:{},
        list:null,
        currentPage: 1,     
        totalResult: 0,     
        pageSizes: [10, 20, 30, 50],   
        pageSize: 20,     
        currentRow:1,  
        leaseTermOptions:[{ label: '月', value: '0' },{ label: '季', value: '1' },{ label: '半年', value: '2' },{ label: '整年', value: '3' }],
        rentalOptions:[{ label: '合租', value: '0' },{ label: '整租', value: '1' },{ label: '独栋', value: '2' }],
        statusOptions:[{ label: '上架', value: '0' },{ label: '下架', value: '1' }],
        ids:'',
        checkStatus:0,
        upSum:0,
        downSum:0,
        loading:false,
        user: JSON.parse(sessionStorage.getItem('user')),
      }
    },
    created(){
        this.loading=true
    },
    mounted:function(){
        this.getHouse()
        this.$bus.$on('getHouse', this.getHouse)

    },
    beforeDestroy() {
      // 在该组件弃用删除之后，解绑该事件，减轻$bus的负担
      this.$bus.$off('getHouse')
    },
    methods:{
        getHouse(index){
            if(this.roomDto.groundingStatus==undefined){   
                this.roomDto.groundingStatus=0
            }

            this.$axios.get(this.$httpUrl + '/applet/queryPC',
                {params:{page:this.currentPage,  pageSize:this.pageSize, leaseTerm:this.roomDto.leaseTerm, rentalType:this.roomDto.rentalType, groundingStatus:this.roomDto.groundingStatus, 
                 address:this.roomDto.address, priceMin:this.roomDto.priceMin, priceMax:this.roomDto.priceMax, commissionMin:this.roomDto.commissionMin, commissionMax:this.roomDto.commissionMax,userId:this.user.id
                }})
                .then(res=>{
                    this.list=res.data.data.records
                    this.totalResult = res.data.data.total                 
                    
                    this.upSum = res.data.map.up.length
                    this.downSum = res.data.map.down.length
                    this.loading = false
                    if(index!=undefined){
                        this.currentRow=this.list[index]
                    }else{
                        this.currentRow=this.list[0]
                    }

                })
                .catch(err => {
                    if(err.response.status==402  || err.response.status==401){
                        location.replace("http://localhost:8080");
                    }
                })
        }, 
        resetting(){
            //重置按钮
            this.roomDto={}
            this.getHouse()
        },
        handleCurrentChange({currentPage,pageSize}){
            //分页控制
            this.currentPage=currentPage
            this.pageSize=pageSize

            this.getHouse()
        },
        currentChangeEvent({row}){
            //点击获取当前行数据
            this.currentRow = row;
            this.$refs.Info.open(this.currentRow)
            this.$refs.xTable.clearCurrentRow()
        },
        add(){
            //添加功能
            this.$refs.addProperty.open()
        },
        grounding(e){
            this.roomDto.groundingStatus=e
            this.currentPage=1
            this.getHouse()
        },
        deleteBatch(){
        //删除操作
        this.$confirm('此操作将删除该数据, 是否继续?', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'})
        .then(() => {this.$axios.delete(this.$httpUrl+"/applet/deleteBatch/",{params:{ids:this.ids}})
        .then(res => {    
            if (res.data.code>=1){
            this.$message({
                message: '删除成功',
                type: 'success'
            });
            this.currentPage=1
            this.checkStatus=0
            this.getHouse()
            }else{
            this.$message.error('删除失败');
            }
        }) .catch(err => {
                if(err.response.status==402  || err.response.status==401){
                location.replace("http://localhost:8080");
                }
            })
        }).catch(() => {
            this.$message({
            type: 'info',
            message: '已取消删除'
            });          
        });
        },
        selectAllEvent ({ checked }) {
            //全选复选框后触发的事件
            const records = this.$refs.xTable.getCheckboxRecords()
            //隐藏查询上下架、新增按钮
            this.checkStatus=1

            if(checked==false && records.length==0){
                this.checkStatus=0
                return
            }

            this.ids=''

            records.forEach((element,index) => {
                if(records.length-1==index){
                    this.ids = this.ids + element.id
                    return
                }
                this.ids = this.ids + element.id+','
            });

        },
        selectChangeEvent ({ checked }) {
            //单击复选框后触发的事件
            const records = this.$refs.xTable.getCheckboxRecords()
            //隐藏查询上下架、新增按钮
            this.checkStatus=1

            if(checked==false && records.length==0){
                this.checkStatus=0
                return
            }

            this.ids=''

            records.forEach((element,index) => {
                if(records.length-1==index){
                    this.ids = this.ids + element.id
                    return
                }
                this.ids = this.ids + element.id+','
            });

        },
        editGrounding(e){

            var data = [this.ids,e]

            this.$axios.post(this.$httpUrl+"/applet/editGrounding",data)
            .then(res => {
                if(res.data.code==1){
                    if(e==0){
                        this.$message({message: '上架成功',type: 'success'});
                    }else if(e==1){
                        this.$message({message: '下架成功',type: 'success'});
                    }
                    this.checkStatus=0
                    this.currentPage=1
                    this.getHouse()
                    
                }else{
                    if(e==0){
                        this.$message({message: '上架失败',type: 'error'});
                    }else if(e==1){
                        this.$message({message: '下架失败',type: 'error'});
                    }
                    this.checkStatus=0
                    this.getHouse()

                }
            })
        },
        //租房方式字段格式化
        formatterRental({ cellValue }) {
            let item = this.rentalOptions.find(item => cellValue == item.value)
            return item ? item.label : ''
        },
        //户型字段格式化
        formatterSaletype({ row }) {
            return row.chamber+'室'+row.office+'厅'+row.kitchen+'厨'+row.toilet+'卫'           
        },
        //状态字段格式化
        formatterStatus({ cellValue }){
            let item = this.statusOptions.find(item => cellValue == item.value)
            return item ? item.label : ''
        },
        formatterArea({ cellValue }){
            return cellValue+'m²'
        },
        formatterleaseTerm({ cellValue }){
            let item = this.leaseTermOptions.find(item => cellValue == item.value)
            return item ? item.label : ''
        },
    },


}
</script>

<style scoped>
  .conditionBox{
    height: 150px;  
    border-width: 2px; 
    border-style: solid; 
    border-color: rgb(214, 210, 210);
    border-radius: 20px;
    padding: 15px 25px 15px 0 ;
    background-color: #FFFFFF;
  }
  .input-r{
    width: 300px;
    float: right;
    
  }
  .select-r {
    width: 300px;
    float: right;
  }
  .button-r{
    padding-left: 20px
  }
  .table-r{
    height: 590px;
    border-width: 2px; 
    border-style: solid; 
    border-color: rgb(214, 210, 210);
    border-radius: 20px;
    padding: 15px 25px 15px 15px ;
    margin-top: 10px;
    background-color: #FFFFFF;
  }
  .buttonBox{
    height: 50px;
    padding-left: 5px;
  }

/*滚动条整体部分*/
.mytable-scrollbar ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/*滚动条的轨道*/
.mytable-scrollbar ::-webkit-scrollbar-track {
  background-color: #FFFFFF;
  
}
/*滚动条里面的小方块，能向上向下移动*/
.mytable-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 5px;
  border: 1px solid #F1F1F1;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  
}
.mytable-scrollbar ::-webkit-scrollbar-thumb:hover {
  background-color: #A8A8A8;
}
.mytable-scrollbar ::-webkit-scrollbar-thumb:active {
  background-color: #787878;
}
</style>