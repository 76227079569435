<template>
  <el-container style="height: 100%; border: 1px solid #eee">
    <el-aside :width="aside_width" style="background-color: rgb(238, 241, 246); height: 100%;margin-left:-2px">
<!--      左边导航-->
        <Aside :isCollapse="isCollapse"></Aside>
    </el-aside>

    <el-container style="height: 100%">
      <el-header style="text-align: right; font-size: 12px;height: 100%;border-bottom: #cecece 1px solid">
<!--        头部-->
        <Header @doCollapse="doCollapse" :icon="icon"></Header>
      </el-header>

      <el-main style="height: 100% ;padding-bottom: 0px; background-color: #f6f7f8">
<!--        主页面数据-->
<!--        <Main></Main>-->
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Aside from "./Aside";
import Header from "./Header";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Index',
  components: {Header, Aside},
  data(){
    return{
      isCollapse:false,
      aside_width:'170px',
      icon:'el-icon-s-fold'
    }
  },

  methods:{
    doCollapse(){
      this.isCollapse = !this.isCollapse
      if (!this.isCollapse) { //展开
        this.aside_width='170px'
        this.icon='el-icon-s-fold'
      } else { //收起
        this.aside_width='64px'
        this.icon='el-icon-s-unfold'
      }
    }
  }

}
</script>

<style scoped>

.el-header {
  /*background-color: #B3C0D1;*/
  color: #333;
  line-height: 60px;
}
</style>