<template>
  <div>
    <div class="conditionBox">
      <el-form ref="form" :model="UserDto">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="名字"  >
              <el-input v-model="UserDto.name" placeholder="昵称" class="input-r" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号"  >
              <el-input v-model="UserDto.phone" placeholder="手机号" class="input-r" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="职位"  >
              <el-select v-model="UserDto.position" placeholder="请选择"  clearable  class="select-r">
              <el-option
                  v-for="item in positionOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="状态">
              <el-select v-model="UserDto.status" placeholder="请选择"  clearable  class="select-r">
              <el-option
                  v-for="item in statusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="button-r">
        <el-button type="primary" @click="getUser()"  icon="el-icon-search" size="small" plain>查询</el-button>
        <el-button type="info"    @click="resetting()" icon="el-icon-refresh" size="small"  plain>重置</el-button>
      </div>

    </div>

      <div class="table-r">
        <div class="buttonBox">
          <el-button style="font-size: 13px;" type="primary"  @click="add()"  icon="el-icon-plus" size="small" plain>新增用户</el-button>
          <el-button style="font-size: 13px;" type="warning" @click="rechargeBalance()"   icon="el-icon-coin" size="small" plain>充值线索币</el-button>
          <el-button style="font-size: 13px;" type="danger"  @click="operationalState(0)"  icon="el-icon-delete" size="small" plain>禁用</el-button>
          <el-button style="font-size: 13px;" type="success"  @click="operationalState(1)"  icon="el-icon-delete" size="small" plain>恢复状态</el-button>
        </div>
        <vxe-table
          border
          show-header-overflow
          show-overflow
          :row-config="{isHover: true,height: 40,isCurrent: true,}"
          height="550px"
          :data="list"
          :loading-config="{icon: 'vxe-icon-indicator roll', text: '正在拼命加载中...'}"
          stripe
          round
          @current-change="currentChangeEvent"
          :seq-config="{startIndex: (currentPage - 1) * pageSize}"
          ref="xTable"
          align="center"
          class="mytable-scrollbar"
          style="width: 1650px; margin-top: 20px; margin-left: 10px;"
        >
          <vxe-column type="seq" title="序号" width="60"></vxe-column>
          <vxe-column field="username" title="名字"  ></vxe-column>
          <vxe-column field="phone" title="手机号"  ></vxe-column>
          <vxe-column field="position" title="职位"  :formatter="formatterRental" ></vxe-column>
          <vxe-column field="balance" title="余额"  :formatter="formatterNum"> </vxe-column>
          <vxe-column field="activatePackage" title="开通套餐类型"  :formatter="formatterActivatePackage"> </vxe-column>
          <vxe-column field="administratorsId" title="开通人"  :formatter="formatterAdministratorsId"> </vxe-column>
          <vxe-column field="openingTime" title="剩余端口时长"  :formatter="formatterRemainder"></vxe-column>
          <vxe-column field="status" title="状态"   :formatter="formatterState"></vxe-column>
          <vxe-column field="loginTime" title="最近一次登录" ></vxe-column>
          <vxe-column field="createTime" title="创建时间"  ></vxe-column>
        </vxe-table>
      <div style="float: right;">
        <vxe-pager
          background
          :current-page.sync="currentPage"
          :page-size.sync="pageSize"
          :total="totalResult"
          :layouts="['PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']"
          :page-sizes="pageSizes"
          @page-change="handleCurrentChange"
          align="left"
          size="mini" 

          >
        </vxe-pager>
      </div>
  </div>
    <vxe-modal v-model="val1" title="充值" width="600" height="400" show-zoom   ><!-- :before-hide-method="clear" -->
      <div  style="margin: 20px;">
        <el-form :model="recharge" ref="rechargeForm" :rules="rulesRecharge" label-position="right" label-width="100px">
          <el-form-item label="账户:" >
              <el-input v-model="phone"   class="formItem"  :disabled=true></el-input>
          </el-form-item>
          <el-form-item label="余额:"  prop="balance">
              <el-input v-model="recharge.balance"   clearable maxlength="8" class="formItem"></el-input>
          </el-form-item>
          <el-form-item label="充值类型:" prop="consumerType">
            <el-radio-group v-model="recharge.consumerType"  class="formItem">
              <el-radio label=1 >充值线索币</el-radio>
              <el-radio label=2 >线索币申诉回退</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注:"  prop="notes">
            <el-input v-model="recharge.notes"   clearable  class="formItem"  type="textarea"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="centered-content">
          <el-button type="primary" @click="submitInto()"   size="small">确 定</el-button>
          <el-button size="small"   @click="clear()">取 消</el-button>
      </div>
    </vxe-modal>
    <vxe-modal v-model="val2" title="添加用户" width="650" height="680" show-zoom  :before-hide-method="clearUser">
      <div style="margin: 20px;">
        <el-form :model="user" ref="addForm" :rules="rules" label-position="right" label-width="100px">
          <el-form-item label="名字:"  prop="name">
            <el-input v-model="user.name"   clearable maxlength="8" class="formItem"></el-input>
          </el-form-item>
          <el-form-item label="手机号:"  prop="phoneNumber">
            <el-input v-model="user.phoneNumber"   clearable class="formItem"></el-input>
          </el-form-item>
          <el-form-item label="密码:"  prop="password">
            <el-input v-model="user.password"   clearable maxlength="12" class="formItem"></el-input>
          </el-form-item>
          <el-form-item label="套餐类型:" prop="activatePackage">
            <el-radio-group v-model="user.activatePackage"  class="formItem" @input="radioSelect">
              <el-radio label=0 style="margin-bottom: 10px;">易出房基础套餐(300币 半年)</el-radio>
              <el-radio label=1 style="margin-bottom: 10px;">易出房高级套餐(550币 一年)</el-radio>
              <el-radio label=2 >易出房顶级套餐(1500币 一年)</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="线索币:"  prop="balance">
            <el-input v-model="user.balance"   clearable  class="formItem"></el-input>
          </el-form-item>
          <el-form-item label="售卖价格:"  prop="price">
            <el-input v-model="user.price"   clearable  class="formItem"></el-input>
          </el-form-item>
          <el-form-item label="主营地区:"  prop="area">
            <el-select v-model="user.area" placeholder="请选择"  clearable  class="formItem">
            <el-option
              v-for="item in areaOptions"
              :key="item.value"
              :label="item.label"
              :value="parseInt(item.value)"
            >
            </el-option>
          </el-select>
          </el-form-item>
          <el-form-item label="备注:"  prop="notes">
            <el-input v-model="user.notes"   clearable  class="formItem"  type="textarea"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="centered-content">
          <el-button type="primary" @click="addUser()"   size="small">确 定</el-button>
          <el-button size="small"   @click="clearUser()">取 消</el-button>
      </div>
    </vxe-modal>
    <vxe-modal v-model="val3" title="暂仅支持微信支付" width="450" height="450" show-zoom >
        <div class="qrcode" ref="qrcodeurl" id="qrContainer" style="display: flex;justify-content: center;align-items: center;height: 70%;"></div>
        <div style="display: flex;justify-content: center;align-items: center;"><img src="../assets/weixin.jpg" style="width: 25px;height: 25px;margin-right: 10px;">请使用微信支付</div>
        <div class="centered-content">
          <el-button type="primary" @click="selectOrder()"   size="small">已支付</el-button>
          <el-button size="small"   @click="clearOrder()">取消</el-button>
        </div>
    </vxe-modal>

  </div>
</template>

<script>
import XEUtils from "xe-utils";
import QRCode from 'qrcodejs2';
export default {
  
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Users",
  data() {
    return {
      val1:false,
      val2:false,
      val3:false,
      qrcode: null,
      payStatus:false,
      orderInfo:{},
      UserDto:{},
      user:{},
      recharge:{},
      phone:'',
      statusOptions:[{ label: '禁用', value: '0' },{ label: '正常', value: '1' }],
      positionOptions:[{ label: '二房东', value: '0' },{ label: '中介', value: '1' },{ label: '公寓', value: '2' },{ label: '自由经济人', value: '3' }],
      list:[],
      areaOptions:[{ label: '武汉', value: '0' },{ label: '北京', value: '1' },{ label: '上海', value: '2' },{ label: '深圳', value: '3' },{ label: '杭州', value: '4' },{ label: '南京', value: '5' },{ label: '苏州', value: '6' }],
      currentPage: 1,     // 当前页
      totalResult: 0,     // 总条数，根据接口获取数据长度(注意：这里不能为空)
      pageSizes: [10, 20, 30, 50],    // 个数选择器（可修改）
      pageSize: 20,       // 默认每页显示的条数（可修改）
      currentRow: {},  //点击表格获取单格表的数据
      rules: {
        name:[
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        phoneNumber:[
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码', trigger: ['blur'] }
        ],
        password:[
          { required: true, message: '请输入用户密码', trigger: 'blur' },
        ],
        balance:[
          { required: true, message: '请输入充值多少线索币', trigger: 'blur' },
        ],
        price:[
          { required: true, message: '请输入售卖价格', trigger: 'blur' },
        ],
        area:[
          { required: true, message: '请选择主营地区', trigger: 'blur' },
        ],
        activatePackage:[
          { required: true, message: '请选择套餐类型', trigger: 'blur' },
        ]
      },
      rulesRecharge:{
        balance:[
          { required: true, message: '余额必填', trigger: 'blur' },
        ],
        consumerType:[
          { required: true, message: '请选择充值类型', trigger: 'blur' },
        ],
      },
    }
  },
  mounted:function(){
    this.getUser()

  },
  methods:{
      //带条件分页的获取用户列表
    getUser(){

      let id=JSON.parse(JSON.parse(JSON.stringify(sessionStorage.getItem('user')))).id
      this.$axios.get(this.$httpUrl+'/user/getAllUser',
      {
        params:{page:this.currentPage,  pageSize:this.pageSize, name:this.UserDto.name, phone:this.UserDto.phone, position:this.UserDto.position, 
        status:this.UserDto.status,id:id}
      }
      )
      .then(res=>{

        this.list=res.data.data.list
        this.totalResult=res.data.data.total


        //默认高亮表格第一行,并获取第一行数据
        if(this.list!=null){
          this.$refs.xTable.setCurrentRow(this.list[0])
        }
        this.currentRow=this.list[0]
      })
      .catch(err => {
        if(err.response.status==402  || err.response.status==401){
          location.replace("http://localhost:8080");
        }
      })
    },
    add(){
      this.val2=true
    },
    clearOrder(){
      this.val3=false
    },
    pay(){
      var boby;
      if(this.user.activatePackage==0){
          boby='易出房基础套餐'
      }else if(this.user.activatePackage==1){
          boby='易出房高级套餐'
      }else if(this.user.activatePackage==2){
          boby='易出房顶级套餐'
      }

      this.$axios.post(this.$httpUrl+"/order/pay",{phoneNumber: this.user.phoneNumber,price:this.user.price*100,body:boby,validTime:10,payType:'W01',remark:'',type:0})
      .then(res => {
        if(res.data.code==1){
          this.orderInfo=res.data.data
          this.val3=true

        if (document.getElementById('qrContainer')) {
          document.getElementById('qrContainer').innerHTML = ''; // 清除二维码方法
        }
        
        this.$nextTick(()=>{
          this.qrcode = new QRCode(document.getElementById('qrContainer'), {
            text: res.data.data.payinfo, // 替换为实际的链接
            width: 150,
            height: 150,
            colorDark: '#000000',
            colorLight: '#ffffff',
        });
        })
        

        }else if(res.data.code==0){
          this.$message.error(res.data.msg);
        }


      })

    },
    selectOrder(){
      this.$axios.get(this.$httpUrl+"/order/selectOrder?TLid="+this.orderInfo.trxid)
      .then(res => {
        if(res.data.code==1){
          this.$message({message: res.data.data,type: 'success'});
          this.payStatus=true
          this.val3=false
        }else if(res.data.code==0){
          this.$message.error(res.data.msg);
        }

      })

    },
    addUser(){
      this.$refs.addForm.validate((valid) => {
            //判断表单验证是否成功
            if (valid) {
              if(!this.payStatus){
                this.pay()
              }
              
              if(this.payStatus){
                this.user.administratorsId=JSON.parse(sessionStorage.getItem('user')).id
                //发送请求
                this.$axios.post(this.$httpUrl+"/openingRecord/openPort",this.user)
                .then(res => {
                  if(res.data.data==1){
                    this.$message({message: '添加成功',type: 'success'});
                  }else if(res.data.code==0){
                    this.$message.error(res.data.msg);
                  }

                  this.val2=false
                  this.payStatus=false
                  this.user={}
                  this.getUser()

                })
              }


            }
      })
    },
    radioSelect(e){
      // if(e=='0'){
      //   this.user.balance=300
      //   this.user.price=600
      // }else if(e='1'){
      //   this.user.balance=550
      //   this.user.price=800
      // }
    },
    clearUser(){
      this.$refs.addForm.resetFields()
      this.user={}
      this.val2=false
    },
    currentChangeEvent({row}){
      //点击获取当前行数据
      this.currentRow = row;
    },
    handleCurrentChange({currentPage,pageSize}){
      //分页控制
      this.currentPage=currentPage
      this.pageSize=pageSize
    },
    operationalState(i){
      //禁用操作
      this.$confirm('此操作将修改用户状态, 是否继续?', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'})
      .then(() => {
    
      this.$axios.post(this.$httpUrl+"/user/statusEdit",{
        updateUserId:JSON.parse(JSON.stringify(this.currentRow)).id,
        id:JSON.parse(sessionStorage.getItem('user')).id,
        status:i
      })
      .then(res => {    
        if (res.data.code>=1){
          this.$message({
            message: '状态修改成功',
            type: 'success'
          });
          this.getUser()
        }else{
          this.$message.error(res.data.msg);
        }
     }) .catch(err => {
          if(err.response.status==402  || err.response.status==401){
            location.replace("http://localhost:8080");
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消修改状态'
       }); 
      });
      

    },
    submitInto(){
      this.$refs.rechargeForm.validate((valid) => {
            //判断表单验证是否成功
            if (valid) {
              this.recharge.userId=this.currentRow.id

              this.$axios.post(this.$httpUrl+"/recharge/rechargeBalance",this.recharge)
              .then(res => {
                if (res.data.code>=1){
                  //关闭添加或编辑信息界面，将表单清空并刷新表格
                  this.val1=false
                  this.recharge={}

                  this.getUser()
                  
                  this.$message({message: '充值成功',type: 'success'});
                }else{
                  this.$message.error(res.data.msg);
                }
              }) .catch(err => {
                if(err.response.status==402  || err.response.status==401){
                  location.replace("http://localhost:8080");
                }
              })
            }
          })

    },
    rechargeBalance(){
        //打开修改余额窗口
        this.val1=true
        
        this.phone=this.currentRow.phone+'   剩余('+this.currentRow.balance+'线索币)'
        //this.recharge=JSON.parse(JSON.stringify(this.currentRow))
        //this.recharge.balance=this.currentRow.balance
        
    },
    clear(){
      this.val1=false

      this.recharge={}

      this.$refs.rechargeForm.resetFields()

    },
    resetting(){
      //重置按钮
      this.UserDto={}
      this.getUser()
    },
    //职位方式字段格式化
    formatterRental({ cellValue }) {
      let item = this.positionOptions.find(item => cellValue == item.value)
      return item ? item.label : ''
    },
    //状态方式字段格式化
    formatterState({ cellValue }) {
        if(cellValue==0){
          return '禁用'
        }else if(cellValue==1){
          return '正常'
        }

        return ''
    },
    formatterActivatePackage({cellValue}){
      if(cellValue==0){
        return '600套餐'
      }else if(cellValue==1){
        return '800套餐'
      }else if(cellValue==2){
        return '1688套餐'
      }
        return ''
    },
    formatterRemainder({cellValue}){
      //开通时间
      const day = new Date(cellValue);
      // 获取当前日期
      const today= new Date()

      // 计算时间差（以毫秒为单位）
      const timeDifference = day - today;

      // 将时间差转换为天、小时、分钟和秒
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

      return days+'天'
    },
    formatterAdministratorsId({cellValue}){
      if(cellValue==1){
          return '秦博'
      }else if(cellValue==1720){
          return '魏星宇'
      }else if(cellValue==1747){
          return '石义钱'
      }else if(cellValue==1754){
          return '钟米佳'
      }
    },
    formatterNum({cellValue}){
      if(XEUtils.toString(cellValue).lastIndexOf('.')==1){
        return XEUtils.toString(cellValue)+"0"
      }else if(XEUtils.toString(cellValue).includes(".")){
        return   XEUtils.toNumber(cellValue)     
      }else if(cellValue !=null){
        return XEUtils.toString(cellValue)+".00"
      }else{
        return "0.00"
      }

    },

  }
}
</script>

<style scoped>
    .conditionBox{
    height: 100px;  
    border-width: 2px; 
    border-style: solid; 
    border-color: rgb(214, 210, 210);
    border-radius: 20px;
    padding: 15px 25px 15px 0 ;
    background-color: #FFFFFF;
  }
  .input-r{
    width: 300px;
    float: right;
    
  }
  .select-r {
    width: 300px;
    float: right;
  }
  ::v-deep  .el-form-item__label{
    padding: 0 0 0 25px ;
  }
  .button-r{
    padding-left: 20px
  }
  .table-r{
    height: 630px;
    border-width: 2px; 
    border-style: solid; 
    border-color: rgb(214, 210, 210);
    border-radius: 20px;
    padding: 15px 25px 15px 15px ;
    margin-top: 10px;
    background-color: #FFFFFF;
  }
  .buttonBox{
    height: 30px;
    padding-left: 10px;
  }

  .centered-content{
  margin-top: 40px;
  display: flex; /* 创建 Flexbox 布局 */
  justify-content: center; /* 水平居中对齐 */
  align-items: center; /* 垂直居中对齐 */
}

.formItem{
  width: 350px;
  margin-left: 40px;
}

::v-deep .el-form-item__error{
  top: 75%;
  left: 10%;
}

/*滚动条整体部分*/
.mytable-scrollbar ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/*滚动条的轨道*/
.mytable-scrollbar ::-webkit-scrollbar-track {
  background-color: #FFFFFF;
  
}
/*滚动条里面的小方块，能向上向下移动*/
.mytable-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 5px;
  border: 1px solid #F1F1F1;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  
}
.mytable-scrollbar ::-webkit-scrollbar-thumb:hover {
  background-color: #A8A8A8;
}
.mytable-scrollbar ::-webkit-scrollbar-thumb:active {
  background-color: #787878;
}

</style>


