<template>
<div style="display: flex;line-height: 60px;">
  <div style="margin-top: 8px;">
    <i :class="icon" style="font-size:25px;cursor:pointer;" @click="collapse"></i>
  </div>
  <div style="flex: 1;text-align: center;">
    <span> </span>
  </div>
  <el-dropdown>
    <i class="el-icon-arrow-down" style="margin-right: 15px"></i>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item @click.native="logOut">退出登录</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
  <span>{{ user.username }}</span>
</div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
  name: "Header",
  data(){
    return {
      user: JSON.parse(sessionStorage.getItem('user')),
    }
  },
  props:{
    icon:String
  },
  methods:{
    logOut() {
      this.$confirm("确认要退出登录吗？",'提示',{
        confirmButtonText:'确定',
        type: 'warning',
        center: true
      }).then(()=>{
        this.$router.push("/")
        sessionStorage.clear()
      }).catch(()=>{})
    },
    collapse(){
      //传值给父组件
      this.$emit('doCollapse')
    }

  }

}
</script>

<style scoped>

</style>