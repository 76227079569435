<template>
    <div style="display : flex;flex-direction:row; ">
        <div>
            <div class="PersonalInfo">
                <div style="padding-left: 20px;padding-top: 30px;">
                    <span style="font-size: 17px;">个人信息</span>
                    <el-button type="success"  @click="toEdit(0)" icon="el-icon-edit" size="mini"  style="float: right;margin-right: 20px;">编辑信息</el-button>
                </div>
                <div>
                    <div style="padding-left: 20px;padding-top: 30px;float: left;">
                        <el-upload
                        :class="imgPath !== '' ? 'avatar-uploader boxCardUploadImg': 'avatar-uploader'"
                        :action=uploadeUrl
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :data="{id:this.user.id,state:0}"
                        :before-upload="beforeAvatarUpload"
                        :headers="headers"
                        >
                        <img v-if="imgPath" :src="imgPath"   class="avatar">
                        <img v-if="imgPath==null" src="../../assets/gerenyonghutouxiang.png"   class="avatar">
                        </el-upload>
                        <span style="font-size: 12px;">点击头像可进行修改</span><br>
                        <span style="font-size: 12px;">只能上传jpg文件，且不超过2MB</span>
                    </div>
                    <div class="personal">
                        <span style="font-size: 14px;">昵称：{{ user.name }}</span><br>
                        <span style="font-size: 14px;">性别：{{ user.sex=='1' ? '男':'女' }}</span><br>
                        <span style="font-size: 14px;">职位:&nbsp; {{ position }}</span><br>
                        <span style="font-size: 14px;">手机号：{{ user.phone }}</span><br>
                        <span style="font-size: 14px;">邮箱：{{ user.mailbox }}</span>
                    </div>
                </div>
            </div>
            <div class="CompanyInfo">
              <div style="padding-left: 20px;padding-top: 30px;">
                    <span style="font-size: 17px;">公司信息</span>
                    <el-button type="success"  @click="toEdit(1)" icon="el-icon-edit" size="mini"  style="float: right;margin-right: 20px;">编辑信息</el-button>
              </div>
              <div>
                    <div style="padding-left: 20px;padding-top: 30px;float: left;">
                        <el-upload
                        :class="companyImg !== '' ? 'avatar-uploader boxCardUploadImg': 'avatar-uploader'"
                        :action=uploadeUrl
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :data="{id:this.user.id,state:1}"
                        :before-upload="beforeAvatarUpload"
                        :headers="headers"
                        >
                        <img v-if="companyImg" :src="companyImg" class="avatar-company">
                        <img v-if="companyImg==null" src="../../assets/xiugaitouxiang.png" class="avatar-company">
                        </el-upload>
                        <div>
                          <span style="font-size: 12px;">点击图片可进行修改</span><br>
                          <span style="font-size: 12px;">只能上传jpg文件，且不超过2MB</span>
                        </div>
                    </div>
                    <div class="company">
                        <span style="font-size: 14px;">公司名称：{{ user.companyName }}</span><br>
                    </div>
                </div>
            </div>
            <div>
                <img v-if="1" src="../../assets/Q816.png" width="850px" class="imageG">
            </div>
        </div>

        <div class="walletInfo">
            <div class="wallet">
              <div style="padding-left: 20px;">
                <span style="font-size: 16px;">推广币（枚）</span>
              </div>
              <div style="padding-left: 20px;">
                <span style="font-size: 16px;">0.00</span>
              </div>
              <div style="padding-left: 20px;">
                <el-button type="warning" size="small">充值</el-button>
              </div>
            </div>
            <div style="margin-left: 25px; margin-top: 20px;">
              <vxe-table
                border
                show-header-overflow
                show-overflow
                :row-config="{isHover: true,height: 40,isCurrent: true,}"
                height="480px"
                :data="list"
                :loading="loading"
                :loading-config="{icon: 'vxe-icon-indicator roll', text: '正在拼命加载中...'}"
                stripe
                round
                :seq-config="{startIndex: (currentPage - 1) * pageSize}"
                ref="xTable"
                align="center"
                class="mytable-scrollbar"
                style="width: 750px;"
              >
                <vxe-column type="seq" title="序号" width="60"></vxe-column>
                <vxe-column field="consumerPlatform" title="消费平台"  :formatter="formatterConsumerPlatform"></vxe-column>
                <vxe-column field="consumerType" title="摘要"  :formatter="formatterConsumerType"></vxe-column>
                <vxe-column field="consumptionQuantity" title="消费数量"   :formatter="formatterNum"></vxe-column>
                <vxe-column field="createTime" title="时间"   ></vxe-column>
              </vxe-table>
              
            </div>
            <div style="float: right; margin-right: 18px; margin-top: 10px;">
              <vxe-pager
                background
                :current-page.sync="currentPage"
                :page-size.sync="pageSize"
                :total="totalResult"
                :layouts="['PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']"
                :page-sizes="pageSizes"
                @page-change="handleCurrentChange"
                align="left"
                size="mini" 

                >
              </vxe-pager>
            </div>
        </div>
        <EditInfo ref="Edit" ></EditInfo>
    </div>
</template>

  
<script>
import EditInfo from './editInfo.vue'
import XEUtils from "xe-utils";

export default {
// eslint-disable-next-line vue/multi-word-component-names
    name: "Personal",
    components:{
      EditInfo
    },
    data(){
        return{
            user:{},
            imgPath:null,
            companyImg:null,
            personalOrCompany:0,
            Underlay:'../../assets/头像底图.jpg',
            list:null,
            currentPage: 1,     // 当前页
            totalResult: 0,     // 总条数，根据接口获取数据长度(注意：这里不能为空)
            pageSizes: [10, 20, 30, 50],    // 个数选择器（可修改）
            pageSize: 20,       // 默认每页显示的条数（可修改）
            headers:{},
            uploadeUrl:this.$httpUrl+'/user/upload',
            loading:false,
            consumerPlatformOptions:[{ label: '易出房平台', value: '0' },{ label: '咸鱼', value: '1' }],
            consumerTypeOptions:[{ label: '线索扣费', value: '0' },{ label: '季付', value: '1' }],
        }
    },
    computed:{
      position:function(){
        if(this.user.position==0){
          return ' 二房东'
        }else if(this.user.position==1){
          return ' 中介'
        }else if(this.user.position==2){
          return ' 公寓'
        }else if(this.user.position==3){
          return ' 自由经纪人'
        }

        return ''
      }
    },
    created(){
      this.loading=true
    },
    mounted:function(){
        //获取用户信息
       this.user = JSON.parse(window.sessionStorage.getItem('user'))
       this.imgPath=this.user.headSculpture
       this.companyImg=this.user.companyImage
       this.headers.token=this.user.token

       this.$bus.$on('refreshUser', ()=>{this.user = JSON.parse(window.sessionStorage.getItem('user'))})

       this.getDetails()
    },
    beforeDestroy() {
      // 在该组件弃用删除之后，解绑该事件，减轻$bus的负担
      this.$bus.$off('refreshUser')
    },
    methods:{
      getDetails(){
        this.$axios.get(this.$httpUrl+'/consumptionDetails/getDetails',
      {
        params:{page:this.currentPage,  pageSize:this.pageSize, userId:this.user.id}
      }
      )
      .then(res=>{
        this.list=res.data.data.records
        this.totalResult=res.data.data.total


        this.loading = false

        //默认高亮表格第一行,并获取第一行数据
        if(this.list!=null){
          this.$refs.xTable.setCurrentRow(this.list[0])
        }      
        this.currentRow=this.list[0]
      }) .catch(err => {
        if(err.response.stats==402  || err.response.stats==401){
          location.replace("http://localhost:8080");
        }
      })
      },
      handleAvatarSuccess(res) {
        //上传成功时，更新会话存储用户信息中的头像信息
        if(res.data.state==0){
          this.imgPath=res.data.name
        }else{
          this.companyImg=res.data.name
        }

        this.user.headSculpture=this.imgPath
        this.user.companyImage=this.companyImg
        sessionStorage.setItem("user",JSON.stringify(this.user))
      },
      beforeAvatarUpload(file) {
        //上传图片时校验格式与大小
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
      },
      toEdit(state){
        //打开个人信息编辑窗口
        this.$refs.Edit.open(this.user,state)
      },
      handleCurrentChange({currentPage,pageSize}){
        //分页控制
        this.currentPage=currentPage
        this.pageSize=pageSize
      },
      formatterConsumerPlatform({ cellValue }) {
        let item = this.consumerPlatformOptions.find(item => cellValue == item.value)
        return item ? item.label : ''
      },
      formatterConsumerType({ cellValue }) {
        let item = this.consumerTypeOptions.find(item => cellValue == item.value)
        return item ? item.label : ''
      },
      formatterNum({cellValue}){
        if(XEUtils.toString(cellValue).lastIndexOf('.')==1){
          return XEUtils.toString(cellValue)+"0"
        }else if(XEUtils.toString(cellValue).includes(".")){
          return   XEUtils.toNumber(cellValue)     
        }else if(cellValue !=null){
          return XEUtils.toString(cellValue)+".00"
        }else{
          return "0.00"
        }

      },

    },
}
</script>

<style scoped>
    .PersonalInfo{
        width: 850px;
        height: 250px;
        border-width: 2px; 
        border-style: solid; 
        border-color: rgb(214, 210, 210);
        border-radius: 20px;
        margin-bottom: 30px;
        background-color: rgb(255, 255, 255) !important

        
    }
    .CompanyInfo{
        width: 850px;
        height: 250px;
        border-width: 2px; 
        border-style: solid; 
        border-color: rgb(214, 210, 210);
        border-radius: 20px;
        background-color: rgb(255, 255, 255) !important


    }
    .walletInfo{
        min-width: 800px;
        height: 800px;
        border-width: 2px; 
        border-style: solid; 
        border-color: rgb(214, 210, 210);
        border-radius: 20px;
        margin-left: 30px;
        background-color: rgb(255, 255, 255) !important

    }

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;

}
.avatar {
  width: 80px !important;
  height: 80px !important;
  display: block !important;
  border-radius:50%;
  margin-bottom: 10px;
}

.avatar-company{
  width: 80px !important;
  height: 80px !important;
  display: block !important;
  border-radius:15%;
  margin-bottom: 10px;
}

::v-deep .avatar-uploader .el-upload{
    border: 1px #d9d9d9 
}

.company{
  padding-top: 30px; 
  float: right;
  margin-right: 70px;

}

.personal{
  padding-top: 25px; 
  float: right;
  margin-right: 60px;
  line-height: 1.7;
}

.wallet{
  width: 300px; 
  height: 200px; 
  border: 2px solid rgb(214, 210, 210);; 
  margin-left: 30px; 
  margin-top: 30px;   
  line-height: 4;
  border-radius:5%;
  background-color: rgb(255, 255, 255);
  background-color: #f1f3e5;
}
 
.imageG{
  width: 800; 
  height: 200px;  
  border-radius:3%; 
  margin-top: 30px;
}

</style>