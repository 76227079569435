<template>
  <div>
    <!-- <el-select v-model="value" filterable placeholder="请选择" :filter-method="oop">
      <el-option
        v-for="item in options"
        :key="item.id"
        :label="item.name"
        :value="item.name">
      </el-option>
    </el-select>  -->
    <!-- <el-input v-model="inputInfo" id="input_id"></el-input> -->
    <!-- <el-button  @click="uio">默认按钮</el-button> -->
    <div id="container" style="width: 0px;height: 0px;"></div>
      
  </div>
</template>



<script>
import AMapLoader from '@amap/amap-jsapi-loader';

window._AMapSecurityConfig = {
    securityJsCode: "630891a91f1a6eff14deb8d01dc14e8a",
};

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name:"info",
    data() {
        return {
          options: [],
          value: '',
          autoOptions: {
            // extensions:'all'
          },
          autoOptions2: {
            type:'商务住宅|住宅区|住宅小区',
          },
          auto: null,
          auto2: null,
          search_id: 'searchId',
          inputInfo:'',
          map:null,
        }
    },
    mounted() {
      this.getArea()
      this.initAMap()
    },
    methods:{
      initAMap() {
      AMapLoader.load({
        key: "8f6ebf6b03da285aa598be6786c7c6b0", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.PlaceSearch","AMap.Geocoder"]
      })
      .then((AMap) => {
        this.map = AMap
        new AMap.Map("container", {
          // 设置地图容器id
          viewMode: "3D", // 是否为3D地图模式
          zoom: 11, // 初始化地图级别
          center: [114.424309, 30.606689], // 初始化地图中心点位置
        });
     

      this.auto = new AMap.Geocoder(this.autoOptions)
      this.auto2 = new AMap.PlaceSearch(this.autoOptions2)
   
      })
      .catch((e) => {
        console.log(e);
      });
      },
      getPrompt(keyword,operateStatus){
        //不使用箭头函数this指向的是window
        this.auto2.search(keyword,(status, result)=> {
          //查询成功时，result 即对应匹配的 POI 信息
          if(operateStatus==0){
            this.$bus.$emit('getAddressOptionsAdd',result.poiList.pois)
          }else if(operateStatus==1){
            this.$bus.$emit('getAddressOptionsEdit',result.poiList.pois)
          }else if(operateStatus==2){
            this.$bus.$emit('getAddressOptionsClue',result.poiList.pois)
          }
          
        })
      },
      AllAddress(longitude,latitude,operateStatus){
        this.auto.getAddress(new this.map.LngLat(longitude,latitude),(status,result)=>{
          if(operateStatus==0){
            this.$bus.$emit('getAllAddressAdd',result)
          }else if(operateStatus==1){
            this.$bus.$emit('getAllAddressEdit',result)
          }else if(operateStatus==2){
            this.$bus.$emit('getAllAddressClue',result)
          }
        })
      },
      getArea(){
        const user = JSON.parse(window.sessionStorage.getItem('user'))
        switch(user.area){
                case 0:
                    this.autoOptions2.city='武汉'
                    break;
                case 1:
                    this.autoOptions2.city='北京'
                    break;
                case 2:
                    this.autoOptions2.city='上海'
                    break;    
                case 3:
                    this.autoOptions2.city='深圳'
                    break;    
                case 4:
                    this.autoOptions2.city='杭州'
                    break;
                case 5:
                    this.autoOptions2.city='南京'
                    break;
                case 6:
                    this.autoOptions2.city='苏州'
                    break;    
            }
      }

    }
}
</script>

<style scoped>
  .custom-content-marker {
  position: relative;
  width: 25px;
  height: 34px;
}

.custom-content-marker img {
  width: 100%;
  height: 100%;
}

.custom-content-marker .close-btn {
  position: absolute;
  top: -6px;
  right: -8px;
  width: 15px;
  height: 15px;
  font-size: 12px;
  background: #ccc;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 15px;
  box-shadow: -1px 1px 1px rgba(10, 10, 10, .2);
}

.custom-content-marker .close-btn:hover{
  background: #666;
}

</style>